$primary-color: #ff0e21;
$second-color: #14bf98;
$third-color: #0c1c1c;

$rgb-primary-color: 255, 14, 33; //--primary-color *
$rgb-second-color: 20, 191, 152; //--second-color *
$rgb-third-color: 12, 28, 28; //--third-color *

@import "../../../src/styles/vars.scss";
@import "../../../src/styles/styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body.webapp-site {
	--font-title: 'Poppins', sans-serif;
    --font-subtext: 'Roboto', sans-serif;
    --font-text: 'Roboto', sans-serif;
    background: #ebebeb;
    // background: var(--third-color);
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    // &::after {
    //     display: block;
    //     position: absolute;
    //     z-index: 1;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(0, 0, 0, .7);
    //     content: "";
    // }
    app-root main-layout {
        position: relative;
        z-index: 2;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        #header__layout {
            background-color: var(--third-color);
            color: white;
            padding: 0 1rem;
            .header-text__color {
                color: white;
            }
            .login__btn {
                color: white;
            }
        }
        #cttMain {
            flex: 1;
        }
    }

    .bg__auth-not-form {
        background-color: #f7f7f7;
    }

    .cy__btn.mdc-button.btn__outline-primary.hover_btn-outline:hover {
        background-color: var(--primary-color);
        color: white;
    }

    // General

    .title__page {
        text-transform: none;
        color: var(--third-color);
    }

    .dropdown_menu_cy-item {
        .dropdown_menu_cy-action {
            font-family: var(--font-title);
        }
    }

    .custom__card-1 {
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 10px;
        .content__img {
            width: 100%;
            display: block;
            border-radius: 8px;
            background-color: rgba($third-color, 0.2);
            &.default__ctt-image {
                min-height: 210px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin: auto;
                    border-radius: 0;
                }
            }
            img {
                width: 100%;
                display: block;
                border-radius: 8px;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .subtext__card {
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 8px;
            }
            .title__card {
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
                color: var(--primary-color);
            }
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
        .content__price {
            color: var(--second-color);
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
        .content__btns {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            .btn_buy {
                border: 1px solid #D0D5DD;
                box-shadow: 0 1px 2px #1018280d;
                background-color: transparent;
            }
        }
    }

    .custom__card-select {
        padding: 1rem;
        border: 2px solid #EAECF0;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        user-select: none;
        &.active {
            pointer-events: none;
            cursor: default;
            border-color: var(--second-color);
            background-color: rgba($second-color, 0.025);
            .content__head span {
                border-color: var(--second-color);
                &::before {
                    transform: scale(1);
                }
            }
        }
        .content__head {
            width: 100%;
            display: grid;
            grid-template-columns: minmax(0,20px) minmax(0,1fr);
            gap: 10px;
            align-items: center;
            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #EAECF0;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--second-color);
                    transition: all ease-in-out 0.2s;
                    transform: scale(0);
                    transform-origin: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .title__card {
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }

    .custom__mat-select {
        .mat-mdc-select-trigger {
            border-color: #F1F1F1;
            // color: #fff;
        }
    }
    .profile__settings {
        background-color: #fff;
        .title__ps, .current__profile-ps {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
    }
    .process_cart {
        background-color: transparent;
        color: var(--third-color);
        border-radius: 10px;
        .title__pc, .subtitle__pc {
            color: var(--second-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-family: var(--font-title);
        }
        .title__pc {
            color: var(--primary-color);
        }
        .subtitle__pc {
            text-transform: none;
        }
        .card__pc {
            border-radius: 0.75rem;
            border-color: transparent;
            background-color: white;
            // border-color: var(--second-color);
            // background-color: var(--second-color);
            .input_group {
                input, select {
                    background-color: rgba(var(--rgb-second-color), 0.05);
                    border-color: white;
                }
            }
            &.type__order {
                .order__pc {
                    border-radius: 0;
                    border: 0;
                    border-bottom: 1px solid #ddd;
                    table th {
                        background-color: white;
                        color: var(--second-color);
                    }
                }
                .order-total__pc {
                    background-color: transparent;
                    color: var(--third-color);
                    border: 0;
                    padding: 0 1rem;
                }
            }
        }
        .order__pc {
            border: 1px solid var(--second-color);
            border-radius: 0.75rem;
            color: var(--third-color);
            table {
                th {
                    background-color: var(--second-color);
                    border-bottom: 1px solid var(--second-color);
                    color: white;
                }
                td {
                    border-bottom: 1px solid var(--second-color);
                    color: var(--third-color);
                    background-color: white;
                }
                tr:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
        .order-total__pc {
            border-radius: 0.75rem;
            color: white;
            background-color: var(--second-color);
            border-color: var(--second-color);
            font-weight: 500;
        }
        .btn__pay {
            background-color: var(--primary-color);
            color: #fff;
        }
        .btn__pc {
            background-color: var(--primary-color);
            color: #fff;
        }
        .status {
            background-color: rgba(var(--rgb-second-color), 0.555);
            color: white;
        }
    }
    .ctt__checkout {
        .ctt__checkout-divisor {
            grid-template-columns: minmax(0, 1fr);
        }
    }
    .content__pcs {
        width: 1024px;
        display: block;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        max-width: 100%;
        background: #fff;
        padding: 1rem 1.5rem;
        .content__step-pcs {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            &.active__step {
                .icon__step-pcs {
                    .ctt__icon-pcs {
                        background-color: var(--second-color);
                    }
                }
                .title__pcs {
                    color: var(--primary-color);
                }
            }
            .icon__step-pcs {
                .ctt__icon-pcs {
                    background-color: #fff;
                    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.102);
                    margin-bottom: 10px;
                }
            }
            .title__pcs {
                font-family: var(--font-title);
                text-transform: uppercase;
                color: var(--second-color);
            }
        }
    }

    .payments {
        background-color: transparent;
        border-radius: 10px;
        .title__payments {
            color: var(--third-color);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.125rem;
            font-family: var(--font-title);
        }
        .content__payments {
            background-color: white;
            .content__header {
                background-color: rgba(0, 0, 0, 0.039);
            }
        }
    }

    .card-general {
        .btns__card-account {
            text-align: left;
        }
    }

    .register__profile {
		background-color: #fff;
	}

    .content__info-renov {
        background-color: rgba(var(--rgb-primary-color), 0.3);
        color: var(--third-color);
    }

    .content__shop .content__shop-events {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 1rem;
        .shop__events-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            .shop__events-item-event-img {
                width: 100%;
                padding: 1rem;
                padding-bottom: 0;
                // aspect-ratio: 1;
                img {
                    display: block;
                    width: fit-content;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            .shop__events-item-event-detail {
                width: 100%;
                color: var(--second-color);
                flex: 1;
                h3 {
                    font-family: var(--font-title);
                    font-size: 25px;
                    font-weight: 800;
                    letter-spacing: normal;
                }
                p {
                    font-size: 16px;
                    font-family: var(--font-title);
                    letter-spacing: normal;
                    text-transform: none;
                    color: currentColor;
                }
                .event__more-info {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    align-items: start;
                    .event__date, .event__time, .event__location {
                        position: relative;
                        text-transform: uppercase;
                        span {
                            z-index: 1;
                            position: relative;
                            aspect-ratio: 2 / 1.5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-top: 1rem;
                            text-align: center;
                            color: var(--primary-color);
                            font-weight: 700;
                        }
                        i {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            display: block;
                            width: fit-content;
                            height: fit-content;
                            line-height: normal;
                            font-size: 4rem;
                            opacity: 0.09;
                        }
                    }
                }
            }
            .shop__events-item-event-detail-btns {
                justify-content: center;
                // padding-bottom: 1rem;
                margin-top: 1rem;
                .buy__tickets.cy__btn.mdc-button {
                    text-transform: none;
                    font-family: var(--font-text);
                    background-color: var(--primary-color);
                    color: white;
                    .mdc-button__label {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .content__ticketing {
        .title__ticketing {
            color: var(--third-color);
        }
        .content__tickets-by-sector-header {
            .cy__btn.mdc-button.btn__outline.btn__addtocart {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                color: white;
            }
        }
        .content__tickets-by-sector {
            .content__ticket {
                border: 0;
                background: white;
                border-radius: 10px;
                .content__ticket-detail {
                    width: 100%;
                    display: block;
                    .content__ticket-detail-wrapper {
                        display: grid;
                        grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
                        align-items: center;
                        .content__ticket-detail-1 {
                            display: grid;
                            grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
                            align-items: center;
                            img {
                                padding: 0;
                            }
                            h4 {
                                padding-left: 10px;
                            }
                        }
                    }
                    h4 {
                        text-align: left;
                        font-family: var(--font-title);
                        font-weight: 600;
                        color: var(--second-color);
                        line-height: 1.2;
                        text-transform: none;
                        font-size: 16px;
                    }
                    h5 {
                        text-align: left;
                        text-transform: none;
                        font-weight: 800;
                        color: var(--third-color);
                    }
                }
                .content__ticket-btns {
                    background-color: var(--second-color);
                    border-color: white;
                    overflow: hidden;
                    border-color: #ddd;
                    width: 200px;
                    input {
                        background-color: white;
                        color: var(--second-color);
                        border-color: #ddd;
                        padding: 0.6rem;
                    }
                    button {
                        background-color: white;
                        color: var(--second-color);
                        padding: 0.6rem;
                    }
                }
            }
        }
        .content__ticketing-wrapper {
            background-color: transparent;
            border-radius: 0;
            color: white;
        }
        .content__match-event.type__2 {
            grid-template-columns: minmax(0, 200px) minmax(0, 1fr);
            background-color: white;
            border: 0;
            padding: 0;
            h4, h5 {
                color: var(--third-color);
            }
            .match__event-detail {
                padding: 1rem 0.5rem;
                .match__event-name h4 {
                    font-weight: 800;
                    font-family: var(--font-title);
                }
            }
            .match__event-other {
                width: 400px;
                max-width: 100%;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                align-items: start;
                .event__date, .event__time, .event__location {
                    position: relative;
                    color: var(--primary-color);
                    font-weight: 600;
                    text-transform: uppercase;
                    span {
                        z-index: 1;
                        position: relative;
                        aspect-ratio: 2 / 1.5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 1rem;
                        text-align: center;
                    }
                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        display: block;
                        width: fit-content;
                        height: fit-content;
                        line-height: normal;
                        font-size: 4rem;
                        opacity: 0.2;
                    }
                }
            }
        }

        .content__cart {
            background-color: var(--second-color);
            color: white;
            .title__ticketing {
                color: white;
            }
            .cart__countdown h5 {
                color: white;
            }
            .cart__title-ticketing span {
                background-color: var(--second-color);
                color: white;
            }
            .cart__item {
                border-color: var(--third-color);
                background-color: white;
                .cart__item-detail {
                    h4 {
                        color: var(--second-color);
                        text-transform: none;
                    }
                    h5 {
                        color: var(--primary-color);
                        font-weight: 800;
                        font-family: var(--font-title);
                    }
                }
                .cart__item-image .cart__item-delete {
                    top: -18px;
                    left: -6px;
                }
                .cart__item-quantity {
                    color: var(--primary-color);
                    font-weight: 600;
                    .cart__item-quantity-x {
                        color: var(--second-color);
                    }
                }
            }
            hr {
                border-color: white;
            }
            .cy__btn.mdc-button {
                &.btn__outline.btn_clean-cart {
                    background-color: transparent;
                    border-color: transparent;
                    color: white;
                }
                &.btn__second.btn_pay-cart {
                    background-color: var(--primary-color);
                    border-color: var(--primary-color);
                    color: white;
                }
            }
        }
    }

    .checkbox__field span {
        background-color: #bfbfbf;
    }
    
    .float__cart {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 500px;
        margin: 0 auto;
        max-width: 100%;
        background-color: white;
        padding: 0.6rem;
        z-index: 2;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        align-items: center;
        p {
            display: flex;
            align-items: center;
            span {
                flex: 1;
                padding-left: 5px;
            }
        }
        h5 {
            font-weight: 600;
            font-family: var(--font-title);
            font-size: 19px;
        }
    }

    .cart__ticketing-section {
        display: block;
        .btn__close-cart-section {
            display: none;
        }
    }

    .ctt__checkout {
        .process_cart {
            background-color: transparent;
        }
        .title__pc {
            color: var(--primary-color);
        }
        .subtitle__pc {
            color: var(--second-color);
        }
        .card__pc {
            padding: 1rem;
            background-color: white;
            color: var(--third-color);
            .order__pc {
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid #ddd;
                table th {
                    background-color: white;
                    color: var(--third-color);
                }
            }
            .order-total__pc {
                background-color: transparent;
                color: var(--third-color);
                border: 0;
                padding: 0 1rem;
            }
        }
    }
    

    // Responsive
    @media screen and (min-width: 768px) {
        .content__pcs {
            justify-content: space-between;
        }
    }

    @media screen and (min-width: 500px) {
        .float__cart {
            border-radius: 10px 10px 0 0;
            box-shadow: 0 -3px 16px #00000024;
        }
    }

    @media screen and (max-width: 1279px) {
        .content__ticketing-wrapper {
            padding-bottom: 5rem;
        }
        .content__shop .content__shop-events .shop__events-item.type__event .shop__events-item-event-detail {
            h3 {
                font-size: 25px;
            }
            p {
                font-size: 16px;
            }
        }
        .float__cart {
            display: grid;
        }
        .cart__ticketing-section {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            display: none;
            &.active {
                display: block;
            }
            .bg__close {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                z-index: 1;
                opacity: 0.3;
            }
            .btn__close-cart-section {
                display: block;
                position: absolute;
                top: -10px;
                right: -10px;
                padding: 10px;
                outline: none;
                cursor: pointer;
                border: 0;
                background-color: transparent;
                color: white;
                line-height: 1;
                border-radius: 7px;
                &:hover {
                    background-color: white;
                    color: var(--primary-color);
                }
            }
            .content__cart {
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 400px;
                height: auto;
                max-width: calc(100% - (1rem* 2));
                max-height: calc(100% - (1rem* 2));
                overflow: auto;
            }
        }
    }

    @media screen and (max-width: 767px) {
        app-root main-layout #header__layout {
            padding: 0.5rem 1rem;
        }
    }

    @media screen and (max-width: 639px) {
        // .ctt__content-ticketing {
        //     padding-left: 0;
        //     padding-right: 0;
        // }
        // .process_cart {
        //     background-color: transparent;
        //     color: var(--third-color);
        //     .title__pc, .subtitle__pc {
        //         color: var(--third-color);
        //     }
        // }
        // .ctt-process_cart {
        //     padding: 0;
        // }
        .content__shop .content__shop-events {
            grid-template-columns: minmax(0, 1fr);
            .shop__events-item .shop__events-item-event-detail .event__more-info {
                .event__date, .event__time, .event__location {
                    i {
                        font-size: 3rem;
                    }
                    span {
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }
        }
        .content__ticketing .content__match-event.type__2 {
            grid-template-columns: minmax(0, 1fr);
            padding: 1rem;
            .match__event-other {
                margin: 0 auto;
                .event__date, .event__time, .event__location {
                    i {
                        font-size: 3rem;
                    }
                    span {
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }
        }

        // .process_cart {
        //     .title__pc {
        //         display: none;
        //     }
        //     .card__pc:not(.type__order) {
        //         padding: 0;
        //         background-color: transparent;
        //     }
        //     .card__pc.type__order {
        //         background-color: white;
        //         color: var(--second-color);
        //         .subtitle__pc {
        //             color: var(--second-color);
        //         }
        //     }
        // }
        // .ctt__checkout {
        //     .title__pc {
        //         display: block;
        //     }
        //     .subtitle__pc {
        //         color: var(--second-color);
        //     }
        //     .process_cart {
        //         .card__pc {
        //             padding: 0.8rem;
        //             background-color: white;
        //         }
        //     }
        // }
    }
}